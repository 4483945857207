var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CModal',{attrs:{"show":_vm.modalEmail.show,"centered":true,"title":_vm.$t('Email control'),"size":"lg"},on:{"update:show":function($event){return _vm.$set(_vm.modalEmail, "show", $event)}},scopedSlots:_vm._u([{key:"footer-wrapper",fn:function(){return [_c('span')]},proxy:true}])},[(_vm.modalEmail.show)?[_c('ModalEmail',{attrs:{"entity":_vm.modalEmail.invoice,"recipients":_vm.modalEmail.recipients,"contact_types":_vm.modalEmail.contact_types,"subject_prefix":"Factura de Administración - "},on:{"modalUpdated":_vm.onModalEmailUpdated,"parentHide":function($event){_vm.modalEmail.show = false}}})]:_vm._e()],2),_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":_vm.fields || [
        { key: 'consecutive', label: 'Invoice' },
        _vm.helpers.monthName,
        { key: 'month_id', _classes: 'hide' },
        _vm.helpers.identifierText,
        _vm.helpers.ownerNameText,
        _vm.helpers.currencyValue,
        Object.assign({}, _vm.helpers.currencyValue, {key: 'discount_value', label: 'with Discount', _hide: !_vm.discount}), // TODO: Show only on month with discounts
        _vm.helpers.createdAt,
        { key: 'overdue', label: 'CV' },
        { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 180px;' }
      ],"config":Object.assign({}, {name: 'invoice',
      url: 'invoices', // + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''), // TODO: Review this URGENT !!!!
      //route: '/no-users/invoices',
      params: Object.assign({}, {_whits: 'early_payment_discount', _lists: 'buildings,months,contact_types,building_types,property_units', building_id: this.building_id || 0}, (_vm.params || {})),
      //filters: [{ key: 'building_id', multiple: false, default: 'response.data.building_id', use: 'building.identifier', list: 'buildings', callback: this.onGetData, config: { select_all: false } }],
      noCreate: true,
      noOptions: true},
      //_check: true,
      (_vm.config || {})),"sorterValue":(_vm.config ? _vm.config.sorterValue : {}) || { column: 'consecutive', asc: false }},on:{"dataChanged":_vm.onDataChanged,"preparedHelpers":_vm.onPreparedHelpers},scopedSlots:_vm._u([{key:"options",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center","white-space":"nowrap"}},[_c('span',[_c('CButton',{staticClass:"mr-2",attrs:{"type":"button","color":"info"},on:{"click":function($event){return _vm.getInvoice('download', item)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1),_c('CButton',{staticClass:"mr-2",attrs:{"type":"button","color":"light"},on:{"click":function($event){return _vm.getInvoice('preview', item)}}},[_c('CIcon',{attrs:{"name":"cil-low-vision"}})],1),_c('CButton',{staticClass:"mr-2",attrs:{"type":"button","color":"danger"},on:{"click":function($event){return _vm.onEmailInvoice(item)}}},[_c('CIcon',{attrs:{"name":"cil-envelope-closed"}})],1)],1),(item.rec_notes.length)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.building_id ? '<br />' : '|')}}),_c('span',[_c('CButton',{staticClass:"mr-2",class:{ 'mt-1': _vm.building_id, 'ml-2': !_vm.building_id },attrs:{"type":"button","color":"success"},on:{"click":function($event){return _vm.getInvoice('download', item, true)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1),_c('CButton',{staticClass:"mr-2",class:{ 'mt-1': _vm.building_id },attrs:{"type":"button","color":"light"},on:{"click":function($event){return _vm.getInvoice('preview', item, true)}}},[_c('CIcon',{attrs:{"name":"cil-low-vision"}})],1),_c('CButton',{staticClass:"mr-2",class:{ 'mt-1': _vm.building_id },attrs:{"type":"button","color":"success"},on:{"click":function($event){return _vm.onEmailInvoice(item, true)}}},[_c('CIcon',{attrs:{"name":"cil-envelope-closed"}})],1)],1)]:_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }