<template>
  <div>
    <CModal :show.sync="modalEmail.show" :centered="true" :title="$t('Email control')" size="lg">
      <template v-if="modalEmail.show">
        <ModalEmail :entity="modalEmail.invoice" :recipients="modalEmail.recipients" :contact_types="modalEmail.contact_types" subject_prefix="Factura de Administración - " @modalUpdated="onModalEmailUpdated" @parentHide="modalEmail.show = false" />
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <ACRUDTable
      ref="crud_table"
      :fields="
        fields || [
          { key: 'consecutive', label: 'Invoice' },
          helpers.monthName,
          { key: 'month_id', _classes: 'hide' },
          helpers.identifierText,
          helpers.ownerNameText,
          helpers.currencyValue,
          { ...helpers.currencyValue, key: 'discount_value', label: 'with Discount', _hide: !discount }, // TODO: Show only on month with discounts
          helpers.createdAt,
          { key: 'overdue', label: 'CV' },
          { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 180px;' }
        ]
      "
      :config="{
        name: 'invoice',
        url: 'invoices', // + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''), // TODO: Review this URGENT !!!!
        //route: '/no-users/invoices',
        params: { _whits: 'early_payment_discount', _lists: 'buildings,months,contact_types,building_types,property_units', building_id: this.building_id || 0, ...(params || {}) },
        //filters: [{ key: 'building_id', multiple: false, default: 'response.data.building_id', use: 'building.identifier', list: 'buildings', callback: this.onGetData, config: { select_all: false } }],
        noCreate: true,
        noOptions: true,
        //_check: true,
        ...(config || {})
      }"
      :sorterValue="(config ? config.sorterValue : {}) || { column: 'consecutive', asc: false }"
      @dataChanged="onDataChanged"
      @preparedHelpers="onPreparedHelpers"
    >
      <template #options="{item}">
        <td style="text-align: center; white-space: nowrap;">
          <span>
            <CButton type="button" class="mr-2" color="info" @click="getInvoice('download', item)"><CIcon name="cil-cloud-download"/></CButton>
            <CButton type="button" class="mr-2" color="light" @click="getInvoice('preview', item)"><CIcon name="cil-low-vision"/></CButton>
            <CButton type="button" class="mr-2" color="danger" @click="onEmailInvoice(item)"><CIcon name="cil-envelope-closed"/></CButton>
          </span>
          <template v-if="item.rec_notes.length">
            <span v-html="building_id ? '<br />' : '|'" />
            <span>
              <CButton type="button" :class="{ 'mt-1': building_id, 'ml-2': !building_id }" class="mr-2" color="success" @click="getInvoice('download', item, true)"><CIcon name="cil-cloud-download"/></CButton>
              <CButton type="button" :class="{ 'mt-1': building_id }" class="mr-2" color="light" @click="getInvoice('preview', item, true)"><CIcon name="cil-low-vision"/></CButton>
              <CButton type="button" :class="{ 'mt-1': building_id }" class="mr-2" color="success" @click="onEmailInvoice(item, true)"><CIcon name="cil-envelope-closed"/></CButton>
            </span>
          </template>
        </td>
      </template>
    </ACRUDTable>
  </div>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'NU-Invoices',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  watch: {
    building_id(new_value, old_value) {
      if (new_value && new_value !== old_value) this.onGetData(new_value)
    }
  },
  data() {
    return {
      month: {},
      checked: [],
      discount: false,
      modalEmail: {
        show: false,
        invoice: false,
        recipients: []
      }
    }
  },
  methods: {
    getInvoice(type, invoice, rec_notes) {
      const self = this
      if (type === 'preview') {
        // TODO: create a function
        self.$http
          .get(`invoices/${invoice.id}/${type}` + (rec_notes ? '?rec_notes=true' : ''))
          .then(response => {
            window.open(response.data.signed_url, `invoice-${invoice.consecutive}${rec_notes ? '-fixed' : ''}.pdf`)
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      } else {
        // TODO: create a function ???
        self.$http
          .getBlob(`invoices/${invoice.id}/${type}` + (rec_notes ? '?rec_notes=true' : '') /*+ (type === 'email' ? '?is_digital=true' : '')*/)
          .then(response => {
            if (type === 'download') {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
              const fileLink = document.createElement('a')

              fileLink.href = fileURL
              fileLink.setAttribute('download', `invoice-${invoice.consecutive}.pdf`)
              document.body.appendChild(fileLink)

              fileLink.click()
            }
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      }
    },

    onEmailInvoice(invoice, rec_notes) {
      // TODO: create a common function to inject identifier
      /*if (!invoice.building_identifier) {
        invoice.building_identifier = `${this.view_helpers.building_types[invoice.building.building_type_id].name} ${this.view_helpers.property_units[invoice.building.property_unit_id].name}-${invoice.building.code}`
      }*/
      invoice.building_identifier = invoice.building.identifier

      this.modalEmail.recipients = [
        { name: 'Send copy to', emails: [{ email: '', checked: false }], type: 'others' },
        { name: 'Admon copy', emails: [{ email: this.property.email, checked: false }], readonly: true, type: 'others' },
        { name: 'Copy me', emails: [{ email: this.loggedUser.email, checked: false }], readonly: true, type: 'others' }
      ]

      // TODO: order by type_id ??
      for (let contact in invoice.building.contacts.filter(contact => contact.type_id && contact.type_id < 3)) {
        contact = invoice.building.contacts[contact]
        contact.contact.emails.forEach(email => {
          email.checked = false
        })
        this.modalEmail.recipients.push({ ...contact, readonly: true, type: contact.type_id === 1 ? 'to' : 'cc', checked: false, emails: contact.contact.emails })
      }

      this.modalEmail.show = true
      this.modalEmail.invoice = invoice
      this.modalEmail.rec_notes = rec_notes
      this.modalEmail.contact_types = this.view_helpers.contact_types
    },

    onModalEmailUpdated(params) {
      const self = this
      if (params.checked.length) {
        const recipients = { to: [], cc: [], others: [] }
        for (const recipient of params.checked) {
          recipients[recipient.type].push(recipient.email)
        }
        self.$http
          //.get(`admin/invoices/${this.modalEmail.invoice.id}/email?subject=${this.modalEmail.invoice.building_identifier}&` + new URLSearchParams(recipients).toString())
          .get(`admin/invoices/${this.modalEmail.invoice.id}/email?prefix=${params.prefix}&` + new URLSearchParams(recipients).toString() + (this.modalEmail.rec_notes ? '&rec_notes=true' : ''))
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      }
      this.modalEmail.show = false
      this.modalEmail.invoice = false
    },

    onGetData(building_id) {
      //if (month_id) {
      //this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { building_id }, true)
      //}
    },
    onDataChanged() {
      if (this.$refs.crud_table.response.data) this.discount = !!this.$refs.crud_table.response.data.early_payment_discount?.status_id
      this.checked = this.$refs.crud_table.getChecked()
    }
  }
}
</script>
